import React from 'react'
import Translation from '../utils/Translation'
import overviews from '../../data/overviews.json'

const WhatIs = (data) => {
  const {slug, whatIsTitle, whatIsDescription, cardLink, cardPhoto, cardTitle, cardDescription} = data.item
  const itemEN = Translation.getItems(overviews, null, 'slug', 'en').find(it => it.slug === slug)

  return (
    <div className="cert-whatIs slds-container--center slds-m-top--x-large">
      <div className="cert-site_title slds-m-bottom--large">
        <h2>{whatIsTitle}</h2>
      </div>
      <div dangerouslySetInnerHTML={{__html: whatIsDescription}} className="cert-whatIs_description slds-text-align--center slds-container--medium slds-container--center slds-p-bottom--x-large"></div>
      {Boolean(cardLink != '') && (
      <div className="cert-whatIs-card">
        <a aria-hidden="true" className="cert-whatIs-card_link" href={cardLink}>&nbsp;</a>
        <div className="cert-whatIs-card-header cert-whatIs-card-bg">
          <img role="presentation" className="cert-whatIs-card-header_img" src={itemEN ? itemEN.cardPhoto : cardPhoto} />
        </div>
        <div className="cert-whatIs-card-body">
          <div className="slds-text-align--center slds-m-top--medium cert-whatIs-card-body_title">
            {cardTitle}
          </div>
          <div dangerouslySetInnerHTML={{__html: cardDescription}} className="slds-text-align--center slds-m-top--xxx-small cert-whatIs-card-body_description"></div>
        </div>
      </div>
      )}
    </div>
  )
}

export default WhatIs
