import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';
import Translation from '../utils/Translation';
import newsAndOffers from "../../data/newsAndOffers.json";

const NewsOffers = ({lang}) => {
  const i18n = new I18n({ lang });
  const items = Translation.getItems(newsAndOffers, null, 'identifier', lang);

  return (
    <div className="Bgc(gray-light) slds-p-vertical--x-large slds-p-horizontal_medium">
      <div className="cert-newsOffers_title slds-container--medium slds-container--center slds-m-bottom--large">
        <h2>{i18n.text("overviewNewsAndOffers_title")}</h2>
      </div>

      <div className="slds-container--center slds-container--x-large">
        <div className="slds-grid slds-wrap slds-grid--align-center slds-grid--pull-padded slds-grid--vertical-stretch">
          {items.map((item, i) => (
            <div key={i} className="slds-size--1-of-1 slds-small-size--1-of-2 slds-medium-size--1-of-3 slds-p-around--medium">
              <div className="cert-newsOffers-card">
                <div className="cert-newsOffers-card_title">
                  {item.title}
                </div>

                <div className="cert-newsOffers-card_text slds-m-top--x-small">
                  {item.description}
                </div>

                <div className="cert-newsOffers-card_link slds-m-bottom--x-small">
                  <a href={item.ctaLink}>{item.ctaText}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsOffers;
